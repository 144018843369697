<template>
  <div>
    <img
      :src="photo"
      height="200px"
      width="200px"
      style="border-radius: 15px"
      class="mb-1"
      @click="imageModel = true"
    />
    <Button
      icon="ios-cloud-upload-outline"
      @click="imageModel = true"
      style="width: 200px"
      class="d-block"
      >Upload Photo</Button
    >
    <Modal
      v-model="imageModel"
      title="Profile Photo Manage"
      :footer-hide="true"
    >
      <label class="d-block text-center mb-1" for="photo"
        ><code><b>Your Photo ( Image size Should be: 500px )</b></code>
      </label>
      <vue-croppie
        ref="croppieRef"
        :enableOrientation="true"
        :boundary="{ width: 350, height: 350 }"
        :viewport="{ width: 300, height: 300, type: 'square' }"
      >
      </vue-croppie>
      <div class="input-group">
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            id="inputGroupFile01"
            @change="croppie"
          />
          <label class="custom-file-label" for="inputGroupFile01"
            >Choose file</label
          >
        </div>
      </div>
      <div class="text-right">
        <button
          class="btn mt-1 btn-primary"
          @click="crop"
          :disabled="!editImage"
        >
          Upload
        </button>
      </div>
    </Modal>
  </div>
</template>
<script>
import store from "@/store/index";

export default {
  data() {
    return {
      image: new Form({
        photo: null,
      }),
      croppieImage: "",
      cropped: null,
      imageModel: false,
      editImage: false,
      photo: null,
    };
  },
  mounted() {
    this.getResult();
  },
  methods: {
    async getResult() {
      const photo = await this.callApi("get", "/candidate/photo");
      if (photo.status == 200) {
        this.photo = photo.data.photo;
      }
    },
    croppie(e) {
      this.editImage = true;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.croppieRef.bind({
          url: e.target.result,
        });
      };

      reader.readAsDataURL(files[0]);
    },
    crop() {
      let options = {
        type: "base64",
        size: { width: 350, height: 350 },
        format: "png",
      };
      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = this.croppieImage = output;
        this.image.photo = this.croppieImage;
        this.image
          .post("/candidate/update/photo")
          .then((res) => {
            this.imageModel = false;
            this.photo = res.data.photo;
            localStorage.setItem("photo", res.data.photo);
            this.$store.dispatch("setToken", res.data);
            this.s(res.data.message);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    },
  },
};
</script>
